import React from 'react'
import { connect } from 'react-redux'
import { Box, Grid } from '@mui/material'
import Layout from '../../templates/Master'
import LineBreak from '../../components/general/LineBreak'
import Auction from '../../modules/Auction'
import Heading from '../../modules/Heading'
// import Media from '../../modules/Media'
import Form from '../../modules/Form'
import Carousel from '../../modules/Carousel'
import Content from '../../modules/Content'
import Share from '../../modules/Share'
import SocialMediaPost from '../../modules/SocialMediaPost'

const AuctionPage = () => {
    return (
        <Layout breadcrumbs={false}>
            <Auction />
            <LineBreak short />
            {/* <Media media = {[
                "comicconnect-mark-zuckerberg-rookie-card-front-middle.jpg",
                "comic-connect-1992-mark-zuckerberg-rookie-card-poster-frame-NFT.jpg",
                "comicconnect_mark_zuckerberg_rookie_card_front_top_cb8d6fa4c2.jpg" ]} /> */}
            <Box textAlign="center" mt={8}>
                <Heading as='h1' content='Auction for Mark Zuckerberg Collectibles September 24 - 28, 2022' />
            </Box>
            <Content
                alignText="center"
                content="<p>This page gives you details of our general auction format, payments and security, bidding, and tracking your bids. For specific details on bidding and paying for the Card, please see <a href='/auction/bid-pay-cash'>auction/bid-pay-cash</a>.</p><p>For specific details on bidding and paying for the NFT, please see <a className='orange' href='/auction/bid-pay-ethereum'>auction/bid-pay-ethereum</a>. 
                </p>" />
            <LineBreak />
            <Content
                alignText="center"
                content="<h2>Auction Format for Card and NFT</h2><p>The auction for both <a href='/card'>the physical card</a> and <a className='orange' href='/nft'>NFT</a> will follow our normal auction format except for changes in the bidding increments and in the payment process, which will differ between <a href='/card'>the card</a> and  <a className='orange' href='/nft'>the NFT</a>.</p><p>You can bid on both the card and the NFT. For the NFT, we only accept payment in ETH. For the physical card, you bid in $US and can pay in either $US or the ETH equivalent.</p><p>The official auction for the 1/1 Mark Zuckerberg NFT Rookie Card is exclusively right here on ComicConnect. Not available on other NFT marketplaces.</p>
                <p>Please Note: The purchase of the NFT in this sale does not constitute or include an assignment of any copyright in or to the NFT or the Referenced Content.</p>" />
            <LineBreak short/>
            <Content
                alignText="center"
                content="<h2>Extended Bidding</h2><p>Any new bid placed during the final 3 minutes of the auction will extend the lot's end-time in an increment of 3 minutes from the time the bid was placed. For example, if an auction lot that is scheduled to end at 8:10 PM receives a new bid at 8:09 PM, the lot's end time will extend to 8:12 PM. After a lot has not received any new bids for 3 minutes, the lot will end, hammering at the winning bid.</p><p>An auction lot that has not received any new bids before it ends will hammer at it's scheduled end time. For example an auction lot that is scheduled to end at 8:10 PM will not be extended, providing that no additional bids are placed after 8:07 PM.</p>" />
            <LineBreak short/>
            <Content
                alignText="center"
                content="<h2>Do Your Additional / Increased Bids Mean You’re Bidding Against Yourself?</h2><p>No. Your additional bids will not increase your current bid unless another bidder has outbid you. The only time increasing your bid will increase your current bid is if the additional bid meets or exceeds an auction's reserve. For example:</p><p>Assume 'Bidder A' bids $50 on an auction lot that has a hidden reserve of $100. If Bidder A places a new bid of $200, their current bid will only go to $100 to meet the reserve, but not a cent more. If 'Bidder B', in turn, bids $110 on the same lot, the system will only then submit a proxy bid of $115 on behalf of Bidder A (whose hidden max remains $200).</p>" />
            <LineBreak short/>
            <Content
                alignText="center"
                content="<h2>Absentee Bidding</h2><p>If you wish, you may participate in absentee bidding or live bidding on the phone with one of our representatives, contact ComicConnect at 888.779.7377, Int'l 001-212-895-3999, or email <a href='mailto:'>support@comicconnect.com</a> anytime Monday through Friday between 10 AM - 6 PM Eastern. Some personnel will be available at this number during the week the auction ends, but we strongly advise contacting us before the end of the auction to schedule your bids.</p>" />
            <LineBreak short/>
            <Box textAlign="center" my={8}>
                <Heading as='h3' content='What Mark Zuckerberg said on FB' />
            </Box>
            <SocialMediaPost type='facebook' />
            <LineBreak short />
            <Box textAlign="center" mt={8}>
                <Heading as='h3' content='What Mark Zuckerberg said on IG' />
            </Box>
            <Content
                alignText="center"
                content="<p>“In honor of expanding digital collectible NFTs to 100 more countries on Instagram and launching new integrations with Coinbase and Dapper, I᾿m sharing my soon-to-be NFT old little league baseball card, which someone recently found and sent to me... 😂 @mcomicconnect has the backstory on my baseball card.”</p>" />
            <SocialMediaPost />
            <LineBreak />
            <Box textAlign="center" mt={8}>
                <Heading as='h3' variant='h4' content='Sign up to receive auction updates!' width={['auto', 'auto', 'auto', 'auto', 800]} />
            </Box>
            <Content
                alignText="center"
                content="<p>Don't miss this historic event! Please sign up here to stay up to date and receive sneak previews!</p>"
                width={['auto', 'auto', 'auto', 'auto', 800]}/>
            <Form />
            <LineBreak />
            <Box textAlign="center" my={8}>
                <Heading as='h2' content='Photos and Video of the Original 1992 Mark Zuckerberg Baseball Card & NFT' />
            </Box>
            <Carousel video media={[
                "comic-connect-1992-mark-zuckerberg-rookie-card-poster-frame-NFT.jpg",
                "comicconnect-mark-zuckerberg-rookie-card-front-middle.jpg",
                "comicconnect-mark-zuckerberg-rookie-card-day-camp-1992-with-card.jpg",
                "comicconnect-mark-zuckerberg-rookie-card-day-camp-1992-bear.jpg",
                "comicconnect_mark_zuckerberg_rookie_card_day_camp_counselor_c92b07296f.jpg",
                "comicconnect_mark_zuckerberg_rookie_card_irvin_simon_c85982ae3a.jpg",
                "comicconnect_mark_zuckerberg_rookie_card_day_camp_1993_a9968c0984.jpg" ]}/>
            <LineBreak />
            <Box textAlign="center" my={8}>
                <Heading as='h2' width={['auto', 'auto', 'auto', 'auto', 500]} content='Tell your friends about the Mark Zuckerberg baseball card & NFT auction!' />
            </Box>
            <Box textAlign="center" mb={5}>
                <Share />
            </Box>
        </Layout>
    )
}

function mapStateToProps(state) {
    return {}
}
export default connect(mapStateToProps)(AuctionPage)
